import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

// converts number to string representation with K and M.
// toFixed(d) returns a string that has exactly 'd' digits
// after the decimal place, rounding if necessary.
const numFormatter = function (num) {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num >= 1000000 && num < 1000000000) {
    return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
  } else if (num <= 999) {
    return num; // if value < 1000, nothing to do
  } else if (num > 1000000000) {
    return (num / 1000000000).toFixed(1) + "B";
  }
};

const MaxSupplyGraph = () => {
  const [data, setData] = useState([]);
  const [yAxisDomain, setYAxisDomain] = useState([0, 100]); // Default domain
  const [supplyDecrease, setSupplyDecrease] = useState(0); // Default domain

  const getCumulativeBalanceHistory = async (address, contractAddress) => {
    const today = new Date();
    const august20_2022 = new Date("2022-08-20");
    const timeDifference = today.getTime() - august20_2022.getTime();
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    try {
      // Fetch transactions using token transfers action
      const transactionsRes = await axios.get(
        `https://api-nova.arbiscan.io/api/?module=account&action=tokentx&address=${address}&startblock=113472&endblock=latest&contractaddress=${contractAddress}&apikey=CTCMS298Z2JNI3JSGHFXTAGYCW86A7EFWU`
      );
      const transactions = transactionsRes.data.result;

      const cumulativeData = {};
      let cumulativeBalance = 0;

      transactions.forEach((tx) => {
        const date = new Date(tx.timeStamp * 1000);
        const formattedDate = `${
          date.getMonth() + 1
        }/${date.getDate()}/${date.getFullYear()}`; // Format date as MM/DD/YYYY
        cumulativeBalance += parseFloat(tx.value) / 1000000000000000000; // Convert to Ether
        cumulativeData[formattedDate] = cumulativeBalance;
      });

      const balanceHistory = [];
      const startDate = new Date();
      for (let i = daysDifference - 1; i >= 0; i--) {
        const date = new Date(startDate.getTime() - i * 24 * 60 * 60 * 1000);
        const formattedDate = `${
          date.getMonth() + 1
        }/${date.getDate()}/${date.getFullYear()}`; // Format date as MM/DD/YYYY
        const max_supply =
          cumulativeData[formattedDate] !== undefined
            ? 82279600 - cumulativeData[formattedDate]
            : balanceHistory.length > 0
            ? balanceHistory[balanceHistory.length - 1].max_supply
            : 0; // Use the previous day's balance if the current day has no transactions
        balanceHistory.push({ date: formattedDate, max_supply });
      }
      const balanceHistoryFiltered = balanceHistory.filter(
        (entry) => entry.max_supply !== 0
      );

      setData(balanceHistoryFiltered);

      // Calculate the domain for YAxis
      const burnValues = balanceHistoryFiltered.map(
        (entry) => entry.max_supply
      );
      const minY = Math.min(...burnValues);
      const maxY = Math.max(...burnValues);
      setYAxisDomain([minY, maxY]);
      setSupplyDecrease((maxY - minY) / maxY);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const address = "0x000000000000000000000000000000000000dead";
    const contractAddress = "0x0057Ac2d777797d31CD3f8f13bF5e927571D6Ad0";
    getCumulativeBalanceHistory(address, contractAddress);
  }, []);

  const formatXAxis = (tickItem) => {
    const dateParts = tickItem.split("/");
    const month = dateParts[0];
    const day = dateParts[1];
    const year = dateParts[2];
    return `${month}/${day}/${year}`;
  };
  return (
    <ResponsiveContainer
      width="100%"
      aspect={3.5}
      margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
    >
      <div
        style={{
          textAlign: "center",
          color: "whitesmoke",
          paddingTop: "15px",
          paddingBottom: "25px",
          fontSize: "16px",
        }}
      >
        {(supplyDecrease * 100).toFixed(2)}% of all Moons have been burned
      </div>
      <LineChart width={800} height={400} data={data}>
        <XAxis
          dataKey="date"
          axisLine={false}
          tickLine={false}
          stroke="whitesmoke"
          tickFormatter={formatXAxis}
          hide
        />
        <YAxis
          tickFormatter={numFormatter}
          axisLine={false}
          tickLine={false}
          stroke="whitesmoke"
          hide
          domain={yAxisDomain}
        />
        <Tooltip
          formatter={(value) => numFormatter(value)}
          contentStyle={{
            backgroundColor: "transparent",
            border: "none",
            color: "#c770f0",
          }}
          wrapperStyle={{ top: 50 }}
        />
        <Line
          type="monotone"
          dataKey="max_supply"
          stroke="#cc3600"
          dot={false}
          strokeWidth={4}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default MaxSupplyGraph;
