import React from "react";
import { Container } from "react-bootstrap";
import Particle from "../Particle";

function Subscription() {
  return (
    <Container
      fluid
      className="project-section"
      style={{
        minHeight: "100vh",
        position: "relative",
        zIndex: 0,
        paddingBottom: "60px", // Ensures space for footer
      }}
    >
      {/*
      <p style={{ color: "whitesmoke" }}>
        Read this{" "}
        <a
          href="https://np.reddit.com/r/CryptoCurrencyMoons/comments/1cn2s88/announcing_the_return_of_rcc_special_memberships/"
          target="_blank"
        >
          post
        </a>{" "}
        to learn more about r/CryptoCurrency Special Memberships!
      </p>
      */}
      <div
        style={{
          position: "relative",
          height: "calc(100vh - 120px)", // Adjust height dynamically based on available space
          maxWidth: "100%",
        }}
      >
        <iframe
          src="https://membershipdapp.vercel.app/"
          width="100%" // Makes the iframe responsive
          height="100%" // Makes the iframe responsive
          style={{ border: "none" }} // Removes iframe border
        ></iframe>
      </div>
    </Container>
  );
}

export default Subscription;
