import React from "react";
import { numFormatter } from "./HomeUtil.js";
import { Row, Col } from "react-bootstrap";

export default function MainStats() {
  const finalCommunityMembers = 9500000;
  const finalTotalMoonsBurned = 2700000;
  const finalTokenHolders = 217016;
  const finalAdvertisersCount = 75;

  return (
    <div>
      <Row
        className="tech-icons"
        style={{
          justifyContent: "center",
          paddingBottom: "50px",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Col xs={6} sm={6} md={3}>
          {numFormatter(finalCommunityMembers).toLocaleString()}+{" "}
          <span>Community Members</span>
        </Col>
        <Col xs={6} sm={6} md={3}>
          {numFormatter(finalTotalMoonsBurned).toLocaleString()}+{" "}
          <span>Total Moons Burned</span>
        </Col>
        <Col xs={6} sm={6} md={3}>
          {numFormatter(finalTokenHolders)}+ <span>Token Holders</span>
        </Col>
        <Col xs={6} sm={6} md={3}>
          {numFormatter(finalAdvertisersCount)}+ <span>Advertisers</span>
        </Col>
      </Row>
    </div>
  );
}
